<template>
  <div style="margin: 0 auto;text-align: center;">
    <h2>{{ $store.state.Login.Username }},您好</h2>
    <h2>请点击右上角进行功能选择</h2>
    <h3>手机用户在打开扫描时提示是否使用摄像头，请点击允许！</h3>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
</style>
